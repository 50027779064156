/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentOrganization {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}`, query, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/organization`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/organization`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        return postJsonAcceptJson(`${this.url}`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Setting {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/setting`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/setting`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/setting`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/setting`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/setting`, query, { requestHeaders: this.requestHeaders });
    }
}

class Stripe {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async connect(request) {
        return postJsonAcceptJson(`${this.url}/rpc/connect-stripe-account`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class BrandProfilePartner {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async startConnect(request = {}) {
        return postJsonAcceptJson(`${this.url}/rpc/start-brandprofile-partner-connect`, request, /* query: */ null, { requestHeaders: this.requestHeaders });
    }

    async verifyConnect(token) {
        return postJsonAcceptJson(`${this.url}/rpc/verify-brandprofile-partner-connect`, { token }, /* query: */ null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/client`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/client`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this organization
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this organization
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

class Coupon {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/coupon`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/coupon`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/coupon`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/coupon`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/coupon`, query, { requestHeaders: this.requestHeaders });
    }
}

class CouponCode {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/coupon-code`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/coupon-code`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/coupon-code`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/coupon-code`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/coupon-code`, query, { requestHeaders: this.requestHeaders });
    }
}

class CouponEmail {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/coupon-email`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/coupon-email`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/coupon-email`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/coupon-email`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/coupon-email`, query, { requestHeaders: this.requestHeaders });
    }
}

class Product {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/product`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/product`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/product`, request, query, { requestHeaders: this.requestHeaders });
    }

    async publish(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/publish/product`, null, query, { requestHeaders: this.requestHeaders });
    }

    async unpublish(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/unpublish/product`, null, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/product`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/product`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/product`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class ProductPrice {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/product-price`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/product-price`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/product-price`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/product-price`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/product-price`, query, { requestHeaders: this.requestHeaders });
    }
}

class ProductGroup {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/product-group`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/product-group`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/product-group`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/product-group`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/product-group`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/product-group`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkResourceProductServiceProduct {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-resource-product-service-product`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/link-resource-product-service-product`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-resource-product-service-product`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/link-resource-product-service-product`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-resource-product-service-product`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/link-resource-product-service-product`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkCouponProduct {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-coupon-product`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/link-coupon-product`, query, { requestHeaders: this.requestHeaders });
    }

    // async edit(query, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit/link-coupon-product`, request, query, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/link-coupon-product`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-coupon-product`, query, { requestHeaders: this.requestHeaders });
    }

    // async check(query, request) {
    //     return postJsonAcceptJson(`${this.url}/check/link-coupon-product`, request, query, { requestHeaders: this.requestHeaders });
    // }
}

class AccountService {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account-service`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account-service`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account-service`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account-service`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-service`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/account-service`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class Account {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account`, query, { requestHeaders: this.requestHeaders });
    }
}

// this refers to CUSTOMER users who are linked to CUSTOMER ACCOUNTS
class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }
}

// this refers to CUSTOMER users who are linked to CUSTOMER ACCOUNTS
class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/user`, request, null, { requestHeaders: this.requestHeaders });
    }

    // sends an invitation to someone to become a customer of this organization; if they accept they'll be able to choose a password and create an account
    async invite(request) {
        return postJsonAcceptJson(`${this.url}/invite/user`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/user`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/user`, query, { requestHeaders: this.requestHeaders });
    }
}

class AccountOrder {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account-order`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account-order`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account-order`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account-order`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-order`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/account-order`, request, query, { requestHeaders: this.requestHeaders });
    }

    async rpc(query, request) {
        return postJsonAcceptJson(`${this.url}/rpc/account-order`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class AccountInvoice {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account-invoice`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account-invoice`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account-invoice`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account-invoice`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-invoice`, query, { requestHeaders: this.requestHeaders });
    }
}

class AccountPayment {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account-payment`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account-payment`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account-payment`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account-payment`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-payment`, query, { requestHeaders: this.requestHeaders });
    }

    async rpc(query, request) {
        return postJsonAcceptJson(`${this.url}/rpc/account-payment`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class AccountRefund {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/account-refund`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/account-refund`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/account-refund`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/account-refund`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-refund`, query, { requestHeaders: this.requestHeaders });
    }
}

class WebhookReceiver {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/webhook-receiver`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/webhook-receiver`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/webhook-receiver`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/webhook-receiver`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/webhook-receiver`, query, { requestHeaders: this.requestHeaders });
    }
}

class WebhookKey {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/webhook-key`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/webhook-key`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/webhook-key`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/webhook-key`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/webhook-key`, query, { requestHeaders: this.requestHeaders });
    }
}

class WebhookMessage {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/webhook-message`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/webhook-message`, query, { requestHeaders: this.requestHeaders });
    }
}

// this refers to ORGANIZATION ADMINISTRATORS who are linked to the organization
class Admin {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // The create API is only usable by system administrators; organization administrators can INVITE someone to join their organization
    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/admin`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/admin`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/admin`, request, query, { requestHeaders: this.requestHeaders });
    }

    // sends an invitation to someone to join this organization as an admin; if they accept they'll be linked via LinkOrganizationUser (which maybe should have been named LinkOrganizationAdmin)
    async invite(query) {
        return postJsonAcceptJson(`${this.url}/invite/admin`, null, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/admin`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/admin`, query, { requestHeaders: this.requestHeaders });
    }
}

// this refers to ORGANIZATION ADMINISTRATORS who are linked to the organization
class LinkOrganizationUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-organization-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-organization-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-organization-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-organization-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-organization-user`, query, { requestHeaders: this.requestHeaders });
    }
}

class Invite {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/invite`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invite`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invite`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invite`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a client for the browser and it uses session cookies to authenticate to the server.
 */
class BrowserClient {
    constructor(context = {}) {
        this.currentOrganization = new CurrentOrganization(context);
        this.admin = new Admin(context);
        this.brandProfilePartner = new BrandProfilePartner(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        this.coupon = new Coupon(context);
        this.couponCode = new CouponCode(context);
        this.couponEmail = new CouponEmail(context);
        this.invite = new Invite(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.linkOrganizationUser = new LinkOrganizationUser(context);
        this.linkResourceProductServiceProduct = new LinkResourceProductServiceProduct(context);
        this.linkCouponProduct = new LinkCouponProduct(context);
        this.product = new Product(context);
        this.productPrice = new ProductPrice(context);
        this.productGroup = new ProductGroup(context);
        this.accountService = new AccountService(context);
        this.account = new Account(context);
        this.accountPayment = new AccountPayment(context);
        this.accountRefund = new AccountRefund(context);
        this.accountOrder = new AccountOrder(context);
        this.accountInvoice = new AccountInvoice(context);
        this.webhookReceiver = new WebhookReceiver(context);
        this.webhookKey = new WebhookKey(context);
        this.webhookMessage = new WebhookMessage(context);
        this.setting = new Setting(context);
        this.stripe = new Stripe(context);
        this.user = new User(context);
    }
}

export default BrowserClient;

export {
    CurrentOrganization,
    Account,
    Admin, // organization admin
    BrandProfilePartner,
    Client,
    ClientToken,
    Coupon,
    CouponCode,
    CouponEmail,
    LinkAccountUser,
    LinkOrganizationUser,
    LinkResourceProductServiceProduct,
    LinkCouponProduct,
    Product,
    ProductPrice,
    ProductGroup,
    AccountService,
    AccountPayment,
    AccountRefund,
    AccountOrder,
    AccountInvoice,
    WebhookReceiver,
    WebhookKey,
    WebhookMessage,
    Invite,
    Setting,
    Stripe,
    User, // customer user
};
